import React from 'react';

import LogoWhite from '../assets/img/LOGO SOFIE 3.png';
import {BsEnvelope, BsFacebook,BsFillGeoAltFill,BsFillTelephoneFill,BsInstagram, BsSnapchat, BsWhatsapp} from 'react-icons/bs';
const Footer = () => {
  return (
    <footer className='bg-primary py-12'>
      <div className='container mx-auto text-white flex justify-between'>
     
        <div className="flex flex-col items-start gap-2">
       <p className='text-[10px]' > <BsFillTelephoneFill   />71 887 383 / 55 062 915</p>
        <p className='text-[10px]'> <BsEnvelope  /> sofiehotelappart@gmail.com</p>
        <p className='text-[10px]'> < BsFillGeoAltFill />4 Rue Mohedine Klibi EL Manar 2,
        Tunis Tunisie</p>
       </div>
       <div className="flex flex-col items-center gap-10">
       <a href='/'>
       
        <img className='w-[50px]' src={LogoWhite} alt=''/>
        </a>
       <p className='text-[10px] text-gray-500 mt-4 lg:mt-0' >copyright &copy; 2023. All rights reserved.</p></div>
        <div className='flex flex-wrap justify-center gap-4 font-tertiary tracking-[3px] text-[15px] items-center uppercase lg:justify-start lg:gap-x-8'>
          
          <a href='https://www.facebook.com/profile.php?id=100094427034119'><BsFacebook/></a>
        <a href='https://instagram.com/sofiehotelappart?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D'><BsInstagram/></a>
        <a href="https://wa.me/21655062915?fbclid=IwAR2YnnFOtRIUbuifT2kHW2-5lbgmzg4mk-keEU_9Yxcgz4MzEMPyX3rKRRU"><BsWhatsapp/></a>
        <a href='https://www.snapchat.com/add/sofiehotelapp?share_id=s19zqBETs4M&locale=fr-FR'><BsSnapchat/></a>
        </div>
      </div>
      
      
    </footer>

  );
};

export default Footer;
