// rooms images
import Room1Img from "./assets/img/rooms/1.JPG";
import Room1ImgLg from "./assets/img/rooms/1-lg.JPG";
import Room11ImgLg from "./assets/img/rooms/1-1-lg.JPG";
import Room12ImgLg from "./assets/img/rooms/1-2-lg.JPG";
import Room13ImgLg from "./assets/img/rooms/1-3-lg.JPG";
import Room14ImgLg from "./assets/img/rooms/1-4-lg.JPG";
import Room15ImgLg from "./assets/img/rooms/1-5-lg.JPG";
import Room16ImgLg from "./assets/img/rooms/1-6-lg.JPG";

import Room2ImgLg from "./assets/img/rooms/2-lg.JPG";
import Room21ImgLg from "./assets/img/rooms/2-1-lg.JPG";
import Room22ImgLg from "./assets/img/rooms/2-2-lg.JPG";
import Room23ImgLg from "./assets/img/rooms/2-3-lg.JPG";
import Room24ImgLg from "./assets/img/rooms/2-4-lg.JPG";
import Room25ImgLg from "./assets/img/rooms/2-5-lg.JPG";
import Room2Img from "./assets/img/rooms/2.JPG";

import Room3Img from "./assets/img/rooms/E3.JPG";
import Room31ImgLg from "./assets/img/rooms/3-1-lg.JPG";
import Room32ImgLg from "./assets/img/rooms/3-2-lg.JPG";
import Room33ImgLg from "./assets/img/rooms/3-3-lg.JPG";
import Room34ImgLg from "./assets/img/rooms/3-4-lg.JPG";
import Room35ImgLg from "./assets/img/rooms/3-5-lg.JPG";
import Room36ImgLg from "./assets/img/rooms/3-6-lg.JPG";
import Room4Img from "./assets/img/rooms/4lg.JPG";

import Room41ImgLg from "./assets/img/rooms/4-1-lg.JPG";
import Room42ImgLg from "./assets/img/rooms/4-2-lg.JPG";
import Room43ImgLg from "./assets/img/rooms/4-3-lg.JPG";
import Room44ImgLg from "./assets/img/rooms/4-4-lg.JPG";
import Room5Img from "./assets/img/rooms/5.JPG";
import Room51ImgLg from "./assets/img/rooms/5-1-lg.JPG";
import Room52ImgLg from "./assets/img/rooms/5-2-lg.JPG";
import Room53ImgLg from "./assets/img/rooms/5-3-lg.JPG";
import Room54ImgLg from "./assets/img/rooms/5-4-lg.JPG";
import Room55ImgLg from "./assets/img/rooms/5-5-lg.JPG";
import Room6Img from "./assets/img/rooms/6.JPG";
import Room61ImgLg from "./assets/img/rooms/6-1-lg.JPG";
import Room62ImgLg from "./assets/img/rooms/6-2-lg.JPG";
import Room64ImgLg from "./assets/img/rooms/6-4-lg.JPG";
import Room65ImgLg from "./assets/img/rooms/6-5-lg.JPG";
import Room66ImgLg from "./assets/img/rooms/6-6-lg.JPG";
import Room67ImgLg from "./assets/img/rooms/6-7-lg.JPG";

// import icons
import {
  FaWifi,
  FaCoffee,
  FaBath,
  FaParking,
  FaThermometerThreeQuarters,
  FaHotdog,

} from "react-icons/fa";

export const roomData = [
  {
    id: 1,
    name: "Suite Ambassadeur 11",
    description: 
"Cette chambre double est parfaite pour accueillir deux adultes et un enfant. Elle offre un grand lit pour un sommeil confortable ainsi qu'un spacieux canapé pour se détendre. La salle de bain est équipée de produits d'accueil pour assurer votre confort. De plus, un placard élégant est inclus pour ranger vos affaires en toute facilité. Vous pourrez vous divertir grâce à la télévision satellite et rester connectés grâce à la connexion Wi-Fi. Profitez de cet espace chaleureux pour passer un séjour agréable en famille.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Coffee", icon: <FaCoffee /> },
      { name: "Bath", icon: <FaBath /> },
      { name: "Parking Space", icon: <FaParking /> },

      { name: "Breakfast", icon: <FaHotdog /> },
      { name: "Air Conditioner", icon: <FaThermometerThreeQuarters /> },
    ],
    size: 85,
    maxPerson: 3,
    price: 250,
    image: Room11ImgLg,
    imageLg: [
      
      Room1ImgLg,
      Room12ImgLg,
      Room13ImgLg,
      Room14ImgLg,
      Room15ImgLg,
      Room1Img,
      Room16ImgLg,
    ],
    yt:"https://www.youtube.com/embed/OeigfyQvDKk?si=cHvKQgwr3rkjhxKN"
  },
  {
    id: 2,
    name: "Suite Exécutive 25",
    description:
      "Cette chambre spacieuse offre un espace généreux d'au moins 55 mètres carrés. Elle est aménagée avec un grand lit, une télévision satellite, un placard pratique, un canapé élégant, une connexion Wi-Fi fiable et un téléphone pour faciliter vos communications. Vous apprécierez le confort et les équipements de cette chambre qui vous permettront de passer un séjour agréable et pratique.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Coffee", icon: <FaCoffee /> },
      { name: "Bath", icon: <FaBath /> },
      { name: "Parking Space", icon: <FaParking /> },
      
      { name: "Breakfast", icon: <FaHotdog /> },
      { name: "Air Conditioner", icon: <FaThermometerThreeQuarters /> },
    
     
    ],
    size: 55,
    maxPerson: 2,
    price: 200,
    image: Room2Img,
    imageLg: [
      Room2ImgLg,
      Room21ImgLg,
      Room22ImgLg,
      Room23ImgLg,
      Room24ImgLg,
      Room25ImgLg,
    ],
    yt:"https://www.youtube.com/embed/VxAX5h-PSOo?si=Qc2ZT9vhmQ5wUdMy"
  },
  {
    id: 3,
    name: "Suite Royale 42",
    description: "La chambre offre un réveil agréable grâce à son ambiance luxueuse et moderne. Vous pourrez profiter du confort d'un grand lit double de haute qualité, entouré de tous les équipements nécessaires pour assurer votre bien-être tout au long de votre séjour. La salle de bain privée, le téléphone, la télévision, ainsi que les produits d'accueil tels qu'une bouilloire, du gel douche et du shampoing, sont inclus pour votre commodité. De plus, la climatisation vous permet de régler la température selon vos préférences, et la connexion Wi-Fi est offerte gratuitement pour que vous puissiez rester connecté. Par ailleurs, une autre chambre est également disponible, avec deux lits séparés. Cette configuration est idéale pour les voyageurs qui préfèrent avoir leur propre lit ou pour accueillir deux personnes séparément. Cette chambre dispose également d'un joli placard pour ranger vos affaires avec élégance et d'un climatiseur pour vous garantir un environnement intérieur confortable. Avec ces aménagements, vous pouvez vous attendre à passer un séjour plaisant et reposant dans l'une ou l'autre de ces chambres."

    ,
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Coffee", icon: <FaCoffee /> },
      { name: "Bath", icon: <FaBath /> },
      { name: "Parking Space", icon: <FaParking /> },

      { name: "Breakfast", icon: <FaHotdog /> },
      { name: "Air Conditioner", icon: <FaThermometerThreeQuarters /> },
    ],
    size: 125,
    maxPerson: 5,
    price: 350,
    image: Room3Img,
    imageLg: [
      Room31ImgLg,
      Room32ImgLg,
      Room33ImgLg,
      Room34ImgLg,
      Room35ImgLg,
      Room36ImgLg,
      Room25ImgLg,
    ],
    yt:"https://www.youtube.com/embed/LDQqqTtBX5s?si=zK2Buk77pLow5Duw"
  },
  {
    id: 4,
    name: "Suite Ambassadeur 12",
    description: "Cette chambre double est parfaite pour accueillir deux adultes et un enfant. Elle offre un grand lit pour un sommeil confortable ainsi qu'un spacieux canapé pour se détendre. La salle de bain est équipée de produits d'accueil pour assurer votre confort. De plus, un placard élégant est inclus pour ranger vos affaires en toute facilité. Vous pourrez vous divertir grâce à la télévision satellite et rester connectés grâce à la connexion Wi-Fi. Profitez de cet espace chaleureux pour passer un séjour agréable en famille.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Coffee", icon: <FaCoffee /> },
      { name: "Bath", icon: <FaBath /> },
      { name: "Parking Space", icon: <FaParking /> },

      { name: "Breakfast", icon: <FaHotdog /> },
      { name: "Air Conditioner", icon: <FaThermometerThreeQuarters /> },
    ],
    size: 85,
    maxPerson: 3,
    price: 250,
    image: Room4Img,
    imageLg: [Room41ImgLg, Room42ImgLg, Room43ImgLg, Room44ImgLg, Room25ImgLg],
    yt:'https://www.youtube.com/embed/p1yxHA1Abw8?si=5kQc1Y48YBwGrzbE'
  },
  {
    id: 5,
    name: "Suite Exécutive 24",
    description: "Cette chambre spacieuse offre un espace généreux d'au moins 55 mètres carrés. Elle est aménagée avec un grand lit, une télévision satellite, un placard pratique, un canapé élégant, une connexion Wi-Fi fiable et un téléphone pour faciliter vos communications. Vous apprécierez le confort et les équipements de cette chambre qui vous permettront de passer un séjour agréable et pratique.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Coffee", icon: <FaCoffee /> },
      { name: "Bath", icon: <FaBath /> },
      { name: "Parking Space", icon: <FaParking /> },

      { name: "Breakfast", icon: <FaHotdog /> },
      { name: "Air Conditioner", icon: <FaThermometerThreeQuarters /> },
    ],
    size: 55,
    maxPerson: 2,
    price: 200,
    image: Room5Img,
    imageLg: [
      Room51ImgLg,
      Room52ImgLg,
      Room53ImgLg,
      Room54ImgLg,
      Room55ImgLg,
      Room25ImgLg,
    ],
    yt:"https://www.youtube.com/embed/mGrWFMqmhe0?si=ijJVWlUvqDRSbI23"
  },
  {
    id: 6,
    name: "Suite Royale 31",
    description: "La chambre offre un réveil agréable grâce à son ambiance luxueuse et moderne. Vous pourrez profiter du confort d'un grand lit double de haute qualité, entouré de tous les équipements nécessaires pour assurer votre bien-être tout au long de votre séjour. La salle de bain privée, le téléphone, la télévision, ainsi que les produits d'accueil tels qu'une bouilloire, du gel douche et du shampoing, sont inclus pour votre commodité. De plus, la climatisation vous permet de régler la température selon vos préférences, et la connexion Wi-Fi est offerte gratuitement pour que vous puissiez rester connecté. Par ailleurs, une autre chambre est également disponible, avec deux lits séparés. Cette configuration est idéale pour les voyageurs qui préfèrent avoir leur propre lit ou pour accueillir deux personnes séparément. Cette chambre dispose également d'un joli placard pour ranger vos affaires avec élégance et d'un climatiseur pour vous garantir un environnement intérieur confortable. Avec ces aménagements, vous pouvez vous attendre à passer un séjour plaisant et reposant dans l'une ou l'autre de ces chambres.",
    facilities: [
      { name: "Wifi", icon: <FaWifi /> },
      { name: "Coffee", icon: <FaCoffee /> },
      { name: "Bath", icon: <FaBath /> },
      { name: "Parking Space", icon: <FaParking /> },

      { name: "Breakfast", icon: <FaHotdog /> },
      { name: "Air Conditioner", icon: <FaThermometerThreeQuarters /> },
    ],
    size: 125,
    maxPerson: 5,
    price: 350,
    image: Room61ImgLg,
    imageLg: [

      Room65ImgLg,
      Room67ImgLg,
      Room66ImgLg,
      
      Room62ImgLg,
      
      Room6Img,
      Room64ImgLg,
      
      
      Room25ImgLg,
    ],
    yt: "https://www.youtube.com/embed/u0262mcGtgQ?si=PkacFhBB5Crgv2Xc",
  },


];
