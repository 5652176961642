import React ,{useState}from 'react';
import { BsCalendar } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../datepicker.css';

const CheckOut = () => {
  const [endDate,setEndDate] = useState(false);
  return (<div className='relative flex items-center justify-end h-full'>
    <div className='absolute z-10 pr-8'>
      <div>
        <BsCalendar className='text-accent text-base'/>
      </div>
    </div>
    <DatePicker className='w-full h-full' selected={endDate} dateFormat="dd/MM/yyyy"  placeholderText='check Out' onChange={(date)=>setEndDate(date)}/>
  </div>);
};

export default CheckOut;
