import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Login from './Login';
import LogoWhite from '../assets/img/LOGO SOFIE 2.png';

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [confirmation, setConfirmation] = useState([]);

  useEffect(() => {
    const storedToken = localStorage.getItem('isLoggedIn');
    if (storedToken) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
   
    if (isLoggedIn) {
      axios
        .get('https://backend.sofie.sofie-appart-hotel.tn/info')
        .then((response) => {
          setUsers(response.data);
          const storedConfirmation = JSON.parse(localStorage.getItem('confirmation'));
          setConfirmation(storedConfirmation || response.data.map(() => "not confirmed"));
          
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
       

    }
  }, [isLoggedIn,confirmation]); 

  const handleLogin = () => {

    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true'); 
  };

  const handleLogout = () => {
    
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn'); 
  };



  const handleResetLocalStorage = () => {
    localStorage.removeItem('confirmation');
    
    setConfirmation(users.map(() => "not confirmed"));
  };
  const handleDelete = (userId) => {
    axios.delete(`https://backend.sofie.sofie-appart-hotel.tn/user/${userId}`)
  .then((response) => {
    setUsers(prevUsers => prevUsers.filter(user => user._id !== userId));
  })
  .catch((error) => {
    console.error('Erreur lors de la suppression de l\'utilisateur :', error);
  });
  };
  



  




  
  

  if (!isLoggedIn) {
    return <Login onLogin={handleLogin} />;
  }
  const confi = (index) => {
    setConfirmation(prevConfirmation => {
      const updatedConfirmation = [...prevConfirmation];
      if (updatedConfirmation[index] === "not confirmed") {
        updatedConfirmation[index] = "en attent";
      } else {
        updatedConfirmation[index] = "confirmed";
      }
      localStorage.setItem('confirmation', JSON.stringify(updatedConfirmation));
      return updatedConfirmation;
    });
    
    
  };
  
  return (
    
    <div className="flex h-screen bg-gray-100">
      
      <div className="flex flex-col flex-1 overflow-hidden">
        {/* Header */}
        <header className="flex-shrink-0">
          <div className="flex items-center justify-between p-4 bg-white border-b">
          <img className='w-[50px]' src={LogoWhite} alt=''/>
            <h2 className="text-xl items-center font-semibold">Admin Dashboard</h2>
           
          </div>
        </header>
        
        {/* Main content */}
        <main className="relative overflow-x-auto shadow-md sm:rounded-lg">
        
          <div className='class="relative overflow-x-auto shadow-md sm:rounded-lg'>
            <strong className='text-gray-708 font-meduim'>Reservation</strong>
          
            <table className='w-full text-[9px]  text-left text-gray-500 dark:text-gray-400' style={{ tableLayout: 'auto' }}>
    <thead className="text-accent text-gray-700 uppercase bg-black-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
        <th className="py-2 px-4">Id</th>
            <th className="py-2 px-4">Name</th>
            <th className="py-2 px-4">First Name</th>
            <th className="py-2 px-4">Email</th>
            <th className="py-2 px-4">Phone Number</th>
            <th className="py-2 px-4">CIN-Passport</th>
            <th className="py-2 px-4">check_in</th>
            <th className="py-2 px-4">check_out</th>
            <th className="py-2 px-4">Number-adults</th>
            <th className="py-2 px-4">Number-kids</th>
            <th className="py-2 px-4">Room</th>
            <th className="py-2 px-4">Confirmation</th>
            <th className="py-2 px-4">Action</th>
        </tr>
    </thead>
    <tbody>
        {/* Mapping through the 'users' array to display user data */}
        {users.map((user, index) => (
            <tr key={user._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className="py-2 px-4 relative dark:text-white" style={{ wordWrap: 'break-word' }}>{user.id}</td>
                <td className="py-2 px-4 relative dark:text-white" style={{ wordWrap: 'break-word' }}>{user.namee}</td>
                <td className="py-2 px-4 relative dark:text-white" style={{ wordWrap: 'break-word' }}>{user.fname}</td>
                <td className="py-2 px-4 relative dark:text-white" style={{ wordWrap: 'break-word' }}>{user.email}</td>
                <td className="py-2 px-4 relative dark:text-white" style={{ wordWrap: 'break-word' }}>{user.phone}</td>
                <td className="py-2 px-4 relative dark:text-white" style={{ wordWrap: 'break-word' }}>{user.cin_pass}</td>
                <td className="py-2 px-4 relative dark:text-white" style={{ wordWrap: 'break-word' }}>{user.check_in}</td>
                <td className="py-2 px-4 relative dark:text-white" style={{ wordWrap: 'break-word' }}>{user.check_out}</td>
                <td className="py-2 px-4 relative dark:text-white" style={{ wordWrap: 'break-word' }}>{user.nb_adult}</td>
                <td className="py-2 px-4 relative dark:text-white" style={{ wordWrap: 'break-word' }}>{user.nb_kids}</td>
                <td className="py-2 px-4 relative dark:text-white" style={{ wordWrap: 'break-word' }}>{user.room}</td>
                {/* Displaying the confirmation status for each user */}
                <td className="py-2 px-4 relative dark:text-white" style={{ wordWrap: 'break-word' }}>{confirmation[index]}</td>
                {/* A button to trigger the 'confi' function with the user index */}
                <td className="py-2 px-4 relative dark:text-white">
                    <button type='button' onClick={() => handleDelete(user.id)} className='bg-accent hover:bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
                        supprimer
                    </button>
                </td>
            </tr>
        ))}
    </tbody>
</table>

<button
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4 ml-4"
          onClick={handleResetLocalStorage}
        >
          Reset Confirmation
        </button>
            

  

          </div>
        </main>
        
        {/* Footer */}
        <footer className="flex-shrink-0">
          <div className="flex items-center justify-center p-4 bg-white border-t">
          <button className="text-red-500" onClick={handleLogout}>Logout</button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default AdminDashboard;







