import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { BsPerson } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import {Swiper,SwiperSlide} from 'swiper/react';
import { RiPhoneFill } from "react-icons/ri";
import { BsCardText } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../datepicker.css";
import { BsCalendar } from "react-icons/bs";
//scroll to comp
import ScrollToTop from "../components/ScrollToTop";
//context
import { RoomContext } from "../context/RoomContext";
import { Menu } from "@headlessui/react";
import { BsChevronDown } from "react-icons/bs";
import  { EffectFade, Autoplay } from "swiper/core";
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const RoomDetails = () => {
  const { rooms } = useContext(RoomContext);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(false);
  const { adults, setAdults } = useContext(RoomContext);
  const { kids, setKids } = useContext(RoomContext);
  const { id } = useParams();
  console.log(id);
  //get room
  const room = rooms.find((room) => {
    return room.id === Number(id);
  });
  const { name, description, facilities, imageLg, price, yt } = room;

  const [namee, setNamee] = useState("");
  const [fname, setFname] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [cin_pass, setCin_pass] = useState();
  const [check_in, setCheck_in] = useState();
  const [check_out, setChek_out] = useState("");
  const [nb_adult, setNb_adult] = useState("");
  const [nb_kids, setNb_kids] = useState("");
  






  const create = async () => {
    const repence = await fetch("https://backend.sofie.sofie-appart-hotel.tn/user", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        namee,
        fname,
        email,
        phone,
        cin_pass,
        check_in,
        check_out,
        nb_adult,
        nb_kids,
        name,
      }),
    });
    
    /*if(check_out>check_in){toast.error("check Date please !")}
    
    else*/ if (repence.ok) {
     
      toast.success("Booking succeed we will contact you ", { position: toast.POSITION.TOP_CENTER
    })
      console.log("reservation submited");
      setTimeout(() => {
        window.location.href = "/"; // Replace "/home" with the actual URL of your home page
      }, 4000);
    } else {
      toast.error("something went wrong !")
      console.log("erreur");
    }
  };
  const lis = [
    { name: "1 Adult" },
    { name: "2 Adult" },
    { name: "3 Adult" },
    { name: "4 Adult" },
  ];
  const list=[
    {name:'0 kids'},
    {name:'1 kid'},
    {name:'2 kids'},
    {name:'3 kids'},
    
  ]
  return (
    <form method="POST">
      <ScrollToTop />
      {/**banner */}
      <div className="bg-hotel bg-cover bg-center  h-[560px] relative flex justify-center items-center ">
        {/**overlay */}
        <div className="absolute w-full h-full bg-black/70"></div>
        {/**title*/}
        <h1 className="text-6xl text-white z-20 font-primary text-center">
          {name} Details
        </h1>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row h-full py-24">
          <div className="w-full h-full lg:w-[70%] px-6">
            <h2 className="h2">{name}</h2>
            <p className="mb-8"></p>
            <Swiper modules={[EffectFade,Autoplay]}
     effect={'fade'} 
     loop={true}
     autoplay={{
       delay:3000,
        disableOnInteraction:true,
  }}>
          {imageLg.map((image, index) => (
            <SwiperSlide key={index}>
             
                <img src={image} alt={`Room ${index}`} loading="lazy"/>  
            </SwiperSlide>
          ))}
        </Swiper>
            <div className="mt-12">
              <h3 className="h3 mb-3">Room Facilities</h3>
              <p className="mb-12">
               
              {description}

              </p>
              <div className="grid grid-cols-3 gap-6 mb-12">
                {facilities.map((item, index) => {
                  const { name, icon } = item;
                  return (
                    <div
                      className="flex items-center gap-x-3 flex-1"
                      key={index}
                    >
                      <div className="text-3x1 text-accent">{icon}</div>
                      <div className="text-base">{name}</div>

                    </div>
                  );
                })}
                
              </div>
              
            </div>
            <div className="mb-12">
            <div className="w-full h-0 relative" style={{ paddingBottom: '56.25%' }}>
      {/* The '56.25%' aspect ratio provides a 16:9 ratio, which is typical for YouTube videos */}
      <iframe
        className="absolute top-0 left-0 w-full h-full"
        src={yt}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />


           
              </div>
            </div>
          </div>
          
          <div className="w-full rounded-lg h-full lg:w-[60%]">
            
            {/** reservation */}
            <div className="py-8 px-6 rounded-lg bg-accent/40 mb-12">
              <div className="flex flex-col space-y-2 mb-4">
                <h3>Your Reservation</h3>
                <div className="h-[60px]">
                  <div className="relative flex items-center">
                    <input
                      className="pl-8 py-2 w-full  border-gray-300 focus:outline-none focus:border-accent"
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={namee}
                      onChange={(e) => setNamee(e.target.value)}
                      required
                    />
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                      <BsPerson className="text-accent text-base" />
                    </div>
                  </div>
                </div>
                <div className="h-[60px]">
                  <div className="relative flex items-center">
                    <input
                      className="pl-8 py-2 w-full  border-gray-300 focus:outline-none focus:border-accent"
                      type="text"
                      placeholder=" First name"
                      name="fname"
                      onChange={(e) => setFname(e.target.value)}
                      required
                    />
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                      <BsPerson className="text-accent text-base" />
                    </div>
                  </div>
                </div>
                <div className="h-[60px]">
                  <div className="relative flex items-center">
                    <input
                      className="pl-8 py-2 w-full  border-gray-300  focus:outline-none focus:border-accent"
                      type="email"
                      placeholder=" Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                      <BsEnvelope className="text-accent text-base" />
                    </div>
                  </div>
                </div>
                <div className="h-[60px]">
                  <div className="relative flex items-center">
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                      <RiPhoneFill className="text-accent text-base" />
                    </div>
                    <input
                      className="pl-8 py-2 w-full  border-gray-300  focus:outline-none focus:border-accent"
                      type="tel"
                      placeholder="Phone number"
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="h-[60px]">
                  <div className="relative flex items-center">
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                      <BsCardText className="text-accent text-base" />
                    </div>
                    <input
                      className="pl-8 py-2 w-full  border-gray-300  focus:outline-none focus:border-accent"
                      type="text"
                      placeholder=" CIN or passport number"
                      name="cin_pass"
                      value={cin_pass}
                      onChange={(e) => setCin_pass(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="h-[60px]">
                  <div className="relative flex items-center justify-end h-full">
   
                     <div className="absolute z-10 pr-8">
                      <div>
                        <BsCalendar className="text-accent text-base" />
                      </div>
                    </div>
                    <DatePicker
                      className="w-full h-full"
                      selected={startDate}
                      value={check_in}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="check In"
                      onChange={(date) => {
                        const d = new Date(date).toLocaleDateString("fr-FR");
                        console.log(d);
                        setCheck_in(d);
                     
                      }}
                    /> 
                  </div>
                </div>

                <div className="h-[60px]">
                  <div className="relative flex items-center justify-end h-full">
                    <div className="absolute z-10 pr-8">
                      <div>
                        <BsCalendar className="text-accent text-base" />
                      </div>
                    </div>
                    <DatePicker
                      className="w-full h-full"
                      
                      selected={endDate}
                      value={check_out}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="check Out"
                      onChange={(date) => {
                        const d = new Date(date).toLocaleDateString("fr-FR");
                        console.log(d);
                        setChek_out(d);
                      }}
                    />
                  </div>
                </div>

                <div className="h-[60px]">
                  <Menu as="div" className="w-full h-full bg-white relative">
                    <Menu.Button className="w-full h-full flex items-center justify-between px-8">
                      {adults}
                      <BsChevronDown className="text-base text-accent-hover" />
                    </Menu.Button>
                    <Menu.Items
                      as="ul"
                      className="bg-white absolute w-full flex flex-col z-40"
                    >
                      {lis.map((li, index) => {
                        return (
                          <Menu.Item
                            value={nb_adult}
                            onClick={(e) => {
                              setAdults(li.name);
                              setNb_adult(e.currentTarget.innerText);
                            }}
                            as="li"
                            className="border-b last-of-type:border-b-0 h-12 hover:bg-accent 
        hovert:text-white w-full flex justify-center items-center cursor-pointer"
                            key={index}
                          >
                            {li.name}
                          </Menu.Item>
                        );
                      })}
                    </Menu.Items>
                  </Menu>
                </div>

                <div className="h-[60px]">
                  <Menu as="div" className="w-full h-full bg-white relative">
                    <Menu.Button className="w-full h-full flex items-center justify-between px-8">
                      {kids === "0 kids" ? "No kids" : kids}
                      <BsChevronDown className="text-base text-accent-hover" />
                    </Menu.Button>
                    <Menu.Items
                      as="ul"
                      className="bg-white absolute w-full flex flex-col z-40"
                    >
                      {list.map((li, index) => {
                        return (
                          <Menu.Item
                            value={nb_kids}
                            onClick={(e) => {
                              setKids(li.name);
                              setNb_kids(e.currentTarget.innerText);
                            }}
                            as="li"
                            className="border-b last-of-type:border-b-0 h-12 hover:bg-accent hovert:text-white w-full flex justify-center items-center cursor-pointer"
                            key={index}
                          >
                            {li.name}
                          </Menu.Item>
                        );
                      })}
                    </Menu.Items>
                  </Menu>
                </div>
                <button
                  type="button"
                  className="btn btn-lg btn-primary w-full"
                  onClick={create}
                >
                  book now
                </button>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </form>
  );
};

export default RoomDetails;
