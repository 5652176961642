import React, { useState } from 'react';

const ContactPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  

 
  const contact = async () =>{
    const repence = await fetch("https://backend.sofie.sofie-appart-hotel.tn/contact",{
      method:"POST", 
      headers:{'Content-Type': 'application/json'} , 
      body: JSON.stringify({name,email,message})
  
    })
    if(repence.ok){
      console.log("reservation submited")
    }else{
      console.log("erreur")
    }
  }

  return (
    <section>
      <div className="bg-hotel bg-cover bg-center  h-[560px] relative flex justify-center items-center">
        <div className="absolute w-full h-full bg-black/80"></div>
        <h1 className="text-6xl text-white z-20 font-primary text-center">Contact Us</h1>
      </div>
      <div className="container mx-auto py-12">
        <h2 className="text-4xl mb-6">Get in Touch</h2>
        <form  className="max-w-lg mx-auto">
          <div className="mb-6">
            <label htmlFor="name" className="block mb-2 text-lg">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e)=>setName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-accent"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="email" className="block mb-2 text-lg">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-accent"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="message" className="block mb-2 text-lg">Message</label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={(e)=>setMessage(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-accent resize-none"
              rows="6"
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-lg btn-primary w-full" onClick={contact}>Submit</button>
        </form>
      </div>
    </section>
  );
};

export default ContactPage;
