import React from 'react';
import { BsFillTelephoneFill } from "react-icons/bs";
import {BsFillGeoAltFill} from "react-icons/bs";
import {BsEnvelope} from "react-icons/bs";
const AboutPage = () => {
  return (
    <section>
      <div className="bg-hotel bg-cover bg-center  h-[560px] relative flex justify-center items-center">
        <div className="absolute w-full h-full bg-black/80"></div>
        <h1 className="text-6xl text-white z-20 font-primary text-center">About Us</h1>
      </div>
      <div className="container mx-auto py-12">
        <h2 className="text-4xl mb-6">Our Story</h2>
        <p className="mb-8">
        L'hôtel 4 étoiles Sofie Appart Hotel se trouve à 4,4 km de la place de la Kasbah et à 2,2 km du Parc zoologique de Tunis. Les clients auront accès à un parking sur place pour plus de commodité.

        </p>
        <p className="mb-8">
        Sofie Appart Hotel dispose de 12 pièces comprenant un balcon et un espace de repas. Une TV à écran plat avec des chaînes satellite ainsi qu'un mini-frigo bar sont proposés. Une douche ouverte et une toilette séparée sont également inclus dans ces chambres.

Le Dar Fatma est approximativement à 14 minutes de là.
        </p>
        <p className="mb-8">
        Sofie Appart Hotel est située à 3 km du centre-ville de Tunis et à 0,5 km de l'aéroport international de Tunis-Carthage. L'apparthôtel est aussi à 5 minutes en voiture du Parc du Belvédère. Cet hôtel se trouve près de la gare Goulette Neuve.

        </p>
        <h2 className="text-4xl mb-6">Info</h2>
        <div className="grid grid-cols-3 gap-8">
          <div className="flex flex-col items-center">
            <BsFillTelephoneFill  className="text-accent w-16 h-16 mb-4" />
            <h3 className="text-xl mb-2">Phone</h3>
            <p>71 887 383 / 55 062 915</p>
          </div>
          <div className="flex flex-col items-center">
            <BsEnvelope className=" text-accent w-16 h-16 mb-4" />
            <h3 className="text-xl mb-2">Email</h3>
            <p>sofiehotelappart@gmail.com</p>
          </div>
          <div className="flex flex-col items-center">
            < BsFillGeoAltFill className="text-accent w-16 h-16 mb-4" />
            <h3 className="text-xl mb-2">Adresse</h3>
            <p>4 Rue Mohedine Klibi EL Manar 2, Tunis Tunisie</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutPage;
