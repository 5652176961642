import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import RoomDetails from './pages/RoomDetails';
import AboutPage from './pages/about';
import ContactPage from './pages/contact';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AdminDashboard from './pages/dashboard';
const router = createBrowserRouter([
  {
    path:'/',
    element:<Home />
  },
  {
    path:'/room/:id',
    element:<RoomDetails />
  },
  {
    path:'/about',
    element:<AboutPage />
  }, 
  {
    path:'/contact',
    element:<ContactPage />
  },
  {
    path:'/admin',
    element:<AdminDashboard />
  },
])

 

const App = () => {
  

  return (
    
  <div>
      
    {window.location.pathname !== '/admin' && <Header />} {/* Hide Header on /admin route */}
             
      <RouterProvider  router={router}/>
      
    <Footer />
   
  </div>
  

 )
};

export default App;
